<template>
  <div>
    <!-- FORM -->
    <v-form ref="form">
      <v-card class="mt-2">
        <v-card-title>Taloyhtiön / Kiinteistön tiedot</v-card-title>
        <v-card-subtitle
          >** Julkiset tiedot voidaan näyttää, jos kohde julkaistaan asuntoportaaleihin tai omille
          verkkosivuille</v-card-subtitle
        >
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-checkbox
                :disabled="isDisabled('condominium', 'fetchFromYtj')"
                v-model="fetchFromYtj"
                label="Salli YTJ:n hakea tietoja"
                style="margin-top: 0"
              ></v-checkbox>

              <v-text-field
                :disabled="isDisabled('condominium', 'businessId')"
                class="mb-1"
                v-model="currentCondominium.businessId"
                label="Y-tunnus"
                @input="checkYTJ"
                @change="checkYTJ"
                outlined
                dense
                hide-details
              ></v-text-field>
              <small
                >Esitäytä tietoja syöttämällä y-tunnus. Palvelu hakee taloyhtiön tietoja
                YTJ-palvelusta.</small
              >
            </v-col>
          </v-row>

          <h4 class="mt-1">Perustiedot</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'name')"
                v-model="currentCondominium.name"
                :rules="validations.required"
                label="Nimi"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.buildYear"
                type="number"
                step="1"
                label="Rakennusvuosi **"
                min="1800"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.plotArea"
                type="number"
                step="0.01"
                label="Tontin pinta-ala**"
                suffix="m²"
                min="1"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Osoite</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                v-model="nominatimAddress"
                :disabled="isDisabled('condominium', 'address')"
                :items="foundAddresses"
                :item-text="nominatimHelpers.getAddressListName"
                outlined
                label="Hae osoitteella"
                dense
                return-object
                no-filter
                @change="
                  setAddress($event);
                  clearInput();
                "
                @input.native="getAddresses"
                hide-details
              ></v-autocomplete> </v-col
          ></v-row>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'address')"
                v-model="currentCondominium.address"
                outlined
                dense
                placeholder="Osoite"
                id="autocomplete"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'zipCode')"
                v-model="currentCondominium.zipCode"
                label="Postinumero"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'city')"
                v-model="currentCondominium.city"
                label="Kaupunki"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.neighborhood"
                label="Kaupunginosa"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-autocomplete
                :disabled="isDisabled('condominium', 'country')"
                v-model="currentCondominium.country"
                :items="getCountries()"
                outlined
                clearable
                label="Maa"
                dense
                hide-details
                :rules="[isRequiredByInvoiceService({ ropoCapital: false, talenom: true })]"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <h4 class="mt-1">Muut tiedot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.otherRooms"
                placeholder="Pyykkitupa, pyöräkellari, kuntosali..."
                label="Muut tilat"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.floors"
                v-only-numbers-and-zero
                v-prevent-paste
                label="Kerroksia **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentCondominium.elevator"
                label="Hissi **"
                :items="[
                  { val: true, text: 'Kyllä' },
                  { val: false, text: 'Ei' },
                ]"
                item-value="val"
                item-text="text"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentCondominium.sauna"
                label="Taloyhtiösauna **"
                :items="[
                  { val: true, text: 'Kyllä' },
                  { val: false, text: 'Ei' },
                ]"
                item-value="val"
                item-text="text"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.heating"
                label="Lämmitys **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.antennaSystem"
                label="Antennijärjestelmä **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="2" xl="1">
              <v-text-field
                v-model="currentCondominium.energyClass"
                label="Energialuokka **"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model="currentCondominium.parkingPlaces"
                label="Autopaikat"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-textarea
                v-model="currentCondominium.repairsDone"
                label="Suoritettu korjaukset"
                dense
                outlined
                hide-details
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-textarea
                v-model="currentCondominium.repairsWaiting"
                label="Tulevat korjaukset"
                dense
                outlined
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Hinnat ja lainat</v-card-title>
        <v-container>
          <h4>Hintatiedot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-select
                v-model="currentCondominium.waterBasis"
                :items="['Kulutuksen mukaan', 'Kiinteä']"
                label="Vesimaksun peruste"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.waterCost"
                step="0.01"
                type="number"
                label="Vesimaksu **"
                outlined
                dense
                suffix="€/hlö/kk"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.saunaCost"
                step="0.01"
                type="number"
                label="Saunavuoron hinta"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.parkingCost"
                step="0.01"
                type="number"
                label="Parkkipaikan hinta"
                outlined
                dense
                suffix="€/kk"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                v-model.number="currentCondominium.laundretteCost"
                step="0.01"
                type="number"
                label="Pyykkituvan hinta"
                outlined
                dense
                suffix="€"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <!-- Condominium loans -->
          <condominium-loans ref="condominiumLoans" class="mt-1"></condominium-loans>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Laskutus</v-card-title>
        <v-card-subtitle
          >Jos olet valinnut tilin laskutusasetuksista kiinteistökohdekohtaisen
          kustannuspaikkalaskennan, käytetään kyseisen kiinteistön vuokrakohteiden laskutuksessa
          tätä kustannuspaikkaa.</v-card-subtitle
        >
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'costCentre')"
                v-model="currentCondominium.costCentre"
                label="Kustannuspaikka"
                outlined
                dense
                hide-details
              ></v-text-field>
              <p>
                <small
                  >Muokattaessa kustannuspaikka ei päivity automaattisesti laskuille. Mene tilin
                  asetukset -> laskutus ja tarkista kustannuspaikan asetukset.</small
                >
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mt-2">
        <v-card-title>Yhteystiedot</v-card-title>
        <v-container>
          <h4 class="mt-1">Isännöinti</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.companyName"
                label="Yritys"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.name"
                label="Isännöitsijä"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.phone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.email"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Kiinteistösihteeri/Kirjanpitäjä</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantCompany"
                label="Yritys"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantName"
                label="Kirjanpitäjä"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantPhone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.accountantEmail"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Huolto</h4>

          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.propertyMaintenanceCompany"
                label="Huoltoyhtiö"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.janitor"
                label="Huoltomies"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.janitorPhone"
                label="Puhelin"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3" xl="2">
              <v-text-field
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.janitorEmail"
                label="Email"
                :rules="validations.email2"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h4 class="mt-1">Sähköpostiviestien vastaanottajat</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-select
                :disabled="isDisabled('condominium', 'administrator')"
                v-model="currentCondominium.administrator.waterEqualizationEmailReceivers"
                :items="waterEmailreceivers"
                label="Vesitasauksen laskupyynnön vastaanottajat"
                item-text="text"
                item-value="val"
                multiple
                small-chips
                deletable-chips
                outlined
                hide-details
              ></v-select>
              <small v-if="noWaterReceiverEmail" class="error--text font-weight-bold"
                >Jonkin vastaanottajan sähköposti puuttuu!</small
              >
            </v-col>
          </v-row>

          <h4 class="mt-1">Muut huomiot</h4>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-textarea
                :disabled="isDisabled('condominium', 'notes')"
                v-model="currentCondominium.notes"
                rows="5"
                outlined
                dense
                label="Vapaa tekstikenttä..."
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>

          <v-btn color="info" class="mr-4 mt-5" @click="submit">{{ saveBtnText }}</v-btn>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import invoiceMixins from "../../mixins/invoiceMixins";
import { mapState, mapMutations, mapActions } from "vuex";
import axios from "axios";
import { debounce } from "../../utils/helpers";
import nominatimHelpers from "@/utils/nominatimHelpers";
import validations from "@/validations";
import CondominiumLoans from "./CondominiumLoans.vue";
// import { Loader } from "@googlemaps/js-api-loader";

export default {
  mixins: [mixins, invoiceMixins],

  components: {
    CondominiumLoans,
  },

  props: {
    cond: {
      type: Object,
      default: function () {
        return {};
      },
    },
    saveBtnText: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      addressInput: "",
      fetchFromYtj: false,
      waterEmailreceivers: [
        { text: "Isännöitsijä", val: "administrator" },
        { text: "Kirjanpitäjä", val: "accountant" },
        { text: "Huolto", val: "janitor" },
        { text: "Vuokrakohteen omistajat (Toimeksiantajat)", val: "owners" },
      ],
      nominatimHelpers,
      validations,
    };
  },

  computed: {
    ...mapState("condominium", ["currentCondominium"]),
    ...mapState("nominatim", ["foundAddresses"]),

    nominatimAddress: {
      get() {
        return this.$store.state.nominatim.nominatimAddress;
      },
      set(val) {
        this.setNominatimAddress(val);
      },
    },

    noWaterReceiverEmail() {
      const receivers = this.currentCondominium?.administrator?.waterEqualizationEmailReceivers;
      if (
        receivers &&
        receivers.includes("administrator") &&
        this.currentCondominium.administrator.email == ""
      )
        return true;

      if (
        receivers &&
        receivers.includes("accountant") &&
        this.currentCondominium.administrator.accountantEmail == ""
      )
        return true;
      if (
        receivers &&
        receivers.includes("janitor") &&
        this.currentCondominium.administrator.janitorEmail == ""
      )
        return true;
      return false;
    },
  },

  watch: {
    fetchFromYtj(val) {
      if (val) this.checkYTJ();
    },

    addressInput: debounce(function (newVal) {
      this.getInfoByAddress(newVal);
    }, 400),
  },

  methods: {
    ...mapMutations("condominium", ["setField"]),
    ...mapActions("nominatim", ["getInfoByAddress"]),
    ...mapMutations("nominatim", ["setNominatimAddress"]),

    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("sendcondominium", this.currentCondominium);
      } else {
        this.showPopup("Jotkin kentät puuttuvat tai ovat virheellisiä", "error");
      }
    },

    getAddresses(event) {
      this.addressInput = event.target.value;
    },

    setAddress(data) {
      if (data) {
        const { address, zipCode, city, country } = nominatimHelpers.getAddressData(data);
        this.setField({ field: "address", val: address });
        this.setField({ field: "zipCode", val: zipCode });
        this.setField({ field: "city", val: city });
        this.setField({ field: "country", val: country });
      }
    },

    clearInput() {
      this.$nextTick(() => {
        this.setNominatimAddress(null);
      });
    },

    async checkYTJ() {
      if (this.fetchFromYtj) {
        if (
          this.currentCondominium.businessId &&
          this.currentCondominium.businessId.length >= 9 &&
          this.currentCondominium.businessId.includes("-")
        ) {
          try {
            let res = await axios.get(
              `https://avoindata.prh.fi/bis/v1/${this.currentCondominium.businessId}`
            );
            let data = res.data.results[0];

            this.setField({ field: "name", val: data.name });

            if (data.registedOffices[0]) {
              let city = String(data.registedOffices[0].name).toLowerCase();

              const cityString = city.charAt(0).toUpperCase() + city.slice(1);
              this.setField({ field: "city", val: cityString });
            }

            let administrator = data.addresses.filter((el) => el.version == 1 && el.careOf != null);
            if (administrator[0]) {
              this.setField({ field: "administrator.name", val: administrator[0].careOf });
            }

            let administratorPhone = data.contactDetails.filter(
              (el) => el.version == 1 && el.type == "Matkapuhelin"
            );
            if (administratorPhone[0]) {
              this.setField({ field: "administrator.phone", val: administratorPhone[0].value });
            }

            setTimeout(() => {
              this.$forceUpdate();
            }, 100);
          } catch (err) {
            this.showPopup(
              "Kaikkia kenttiä tai osaa kenttien tiedoista ei voitu täyttää.",
              "error"
            );
          }
        }
      }
    },
  },
};
</script>

<style scoped></style>
